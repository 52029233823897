<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-09 13:45:36
-->
<template>
  <wk-header ref="header" :currentIndex="2"></wk-header>
  <el-main class="min-main">
    <el-row  :gutter="40">
      <el-col :span="18" :offset="3">
        <div class="industry">
          <h3>行业</h3>
          <label
            :class="newsIndustryIndex == item.id ? 'active' : ''"
            v-for="item in newsIndustries"
            :key="item.id"
            @click="handleNewsIndustry(item.id)"
          >
            {{ item.name }}
          </label>
        </div>
        <div class="tool-order" style="margin-top:20px">
          <div>
            <h3>排序</h3>
            <label
              :class="orderIndex == item.id ? 'active' : ''"
              v-for="item in newsOrder"
              :key="item.id"
              @click="handleNewsOrder(item)"
            >
              {{ item.name }}
            </label>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-container class="tool">
      <el-row class="w100" :gutter="20">
        <el-col :span="18" :offset="3">
          <el-row :gutter="50">
            <el-col :span="12" v-for="item in newsList" :key="item">
              <div class="tool-information" @click="handleDetail(item)">
                <img :src="item.mainPic?item.mainPic:''" alt="">
                <div>
                  <p class="tool-information-title el-ellipsis--l2">{{item.title}}</p>
                  <p class="tool-information-industry">{{item.industryStr}}</p>
                  <div class="tool-information-count">
                    <p>{{item.readNums}}人浏览</p>
                    <p v-if="item.duration<60">{{item.duration}}分钟之前发布</p>
                    <p v-else-if="item.duration>=60&&item.duration<1400">{{parseInt(item.duration/60)}}小时之前发布</p>
                    <p v-else-if="item.duration>=1400&&item.duration<2880">24小时之前发布</p>
                    <p v-else-if="item.duration>=2880&&item.duration<4320">48小时之前发布</p>
                    <p v-else>{{item.publishTime}}</p>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-container>
    <el-row class="mt20">
      <el-col :span="18" :offset="3" class="right">
        <el-pagination
          v-model:currentPage="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </el-main>
  <wk-footer></wk-footer>
</template>

<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {ElPagination} from 'element-plus'
import {getAction} from '@/utils/http'
import axios from  'axios'

export default {
  name: 'Tools',
  components: {
    WkHeader,
    WkFooter,
    ElPagination
  },
  data() {
    return {
      // 行业类别
      newsIndustries: [
        {id: 1, name: '字体设计',},
        {id: 2, name: '海报设计',},
        {id: 3, name: '综合教程',},
        {id: 4, name: '综合教程',},
        {id: 5, name: '办公软件',},
        {id: 6, name: '学生专区',},
        {id: 7, name: '编程开发',},
      ],
      newsIndustryIndex: '1',
      // 排序
      newsOrder: [
        {id: '1', name: '最新资讯'},
        {id: '2', name: '最热资讯'},
        {id: '3', name: '浏览最多'},
      ],
      orderIndex: '1',
      currentPage: 1,
      params:{
        pageNo:1,
        pageSize:10,
        industryId:'',
        newFlag:'',
        hotFlag:'',
        mostReadFlag:'',
      },
      urls:{
        getNews:'/wbpt/news/tbWbptNews/list',
      },
      newsList:[],
      total:0,
      pageSize:10,
    }
  },
	mounted(){
    this.params.industryId=""
    this.params.newFlag='1'
    this.getNewsList()
		this.getNewsIndustry()
	},
  // 监听,当路由发生变化的时候执行
  watch:{
    '$route':'getPath'
  },
  methods: {
		//获取行业
		getNewsIndustry:function(){
			this.newsIndustries = []
			getAction("/wbpt/news/home/getNewsIndustry").then(res=>{
				if(res.rows){
					for(let ii=0;ii<res.rows.length;ii++){
						this.newsIndustries.push({
							id:res.rows[ii].id,
							name:res.rows[ii].labelName
						})
					}
				}
			})
		},
		//点击行业查询
    handleNewsIndustry(id) {
      this.newsIndustryIndex = id;
      this.params.industryId = id;
      this.params.pageNo = 1;
      this.getNewsList();
    },

    handleNewsOrder({id}) {
      this.orderIndex = id
      this.params.pageNo = 1;
      this.params.newFlag ='';
      this.params.hotFlag ='';
      this.params.mostReadFlag =''
      if('1'==id){
        this.params.newFlag ='1'
      }else if('2'==id){
        this.params.hotFlag ='1'
      }else if('3'==id){
        this.params.mostReadFlag ='1'
      }else{
        //不做处理
      }
      this.getNewsList();
    },

    handleSizeChange() {
      this.params.pageSize = this.pageSize;
      this.params.pageNo = 1;
      this.getNewsList();
    },
    //点击跳页
    handleCurrentChange() {
      this.params.pageNo = this.currentPage;
      this.getNewsList();
    },
    //点击查看详情
    handleDetail(item) {
      this.addNewsReadnum(item.id)
      if(item.newsUrlPc){
        window.open(item.newsUrlPc,'_blank');
      }else{
        this.$router.push({
          path: '/newsDetail',
          query: {
            newsId: item.id
          }
        })
      }
    },
    //查询资讯
    getNewsList(){
      getAction(this.urls.getNews,this.params).then(res=>{
        if(res.success){
          this.total = res.result.total;
          if(res.result.records.length>0){
            res.result.records.forEach(element => {
              let industryStr = "";
              if(element.industrys.length>0){
                 element.industrys.forEach(item=>{
                   industryStr = industryStr+item.labelName+" ";
                 });
              }
              element.industryStr = industryStr;
            });
          }
          this.newsList = res.result.records;

        }else{
          console.log('获取资讯失败！');
        }
      }).catch(err=>{
          console.log(err);
      });
    },
    //初始化查询
    initGetNewsList(){
      this.params.pageNo = 1;
      this.params.pageSize = 10;
      this.getNewsList();
    },
    //
    getPath(){
      this.params.industryId="";
      this.params.pageNo = 1;
      this.params.pageSize = 10;
      this.getNewsIndustry();
      this.getNewsList();
    },
    //阅读数加1
		addNewsReadnum:function(id){
      let ip = '';
      if(sessionStorage.getItem('ip')){
        ip = sessionStorage.getItem('ip');
      }
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let obj={
        newsId:id,
        userId:userId,
        ip:ip
      }
      getAction("/wbpt/news/home/updateNewsReadnumWithId",obj).then(res=>{
        console.log(res)
      })
		},
  }

}
</script>

<style>

</style>